@import '../../../../advox-mergo-b2b/src/style/abstract/variables';

@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

@import '~advox-basic/src/style/abstract/media';
@import '~advox-basic/src/style/abstract/mixins';

@import '~advox-basic/src/style/abstract/button';
@import '~advox-basic/src/style/abstract/placeholder';

@import '../../../../advox-mergo-b2b/src/style/abstract/button';

:root {
    --checkout-order-summary-scroll-accent-color: #{$default-primary-base-color};
}

.Checkout {
    &-Title,
    &-Heading {
        font-weight: 500;
        font-size: 22px;
        line-height: 32px;
        color: $black;
    }
    .CartPage-CheckoutButton.Button,
    .Checkout-StickyButtonWrapper.CheckoutOrderSummary-ButtonWrapper .CheckoutShipping-Button {
        letter-spacing: initial;
        svg {
            display: none;
        }
    }
    .CheckoutOrderSummary-OrderItems {
        .CartItem-Wrapper_isMobileLayout {
            grid-row-gap: 0;
        }
        .CartItem-ProductActions_isMobileLayout {
            min-width: 300px;
        }
        .CartItem-Wrapper.CartItem-Wrapper_isSummary {
            @include desktop {
                padding: 18px 0 0;
            }
        }
        .CartItem-ProductActions {
            align-items: center;

            .CustomPrice {
                @include mobile {
                    margin: 0;
                }
                @include desktop {
                    margin: 9px 0 0 -24px;
                }
            }
        }
    }
}
