@import '../../../../advox-mergo-b2b/src/style/abstract/variables';

@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

@import '~advox-basic/src/style/abstract/media';
@import '~advox-basic/src/style/abstract/mixins';

@import '~advox-basic/src/style/abstract/button';
@import '~advox-basic/src/style/abstract/placeholder';

@import '../../../../advox-mergo-b2b/src/style/abstract/button';

:root {
    --checkout-step-active-title-color: #{$black};
    --checkout-step-active-count-background: #{$default-primary-base-color};
}

.CheckoutSteps {
    &-StepCount {
        border-radius: 0;
        @include desktop {
            margin-right: 11px;
        }
    }

    &-Step {
        &_canPlaceDirectOrder {
            cursor: auto;
        }

        > div {
            display: flex;
            align-items: center;
            cursor: auto;
        }

        > div span {
            cursor: auto;
        }
    }
}
