@import '../../../../advox-mergo-b2b/src/style/abstract/variables';

@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

@import '~advox-basic/src/style/abstract/media';
@import '~advox-basic/src/style/abstract/mixins';

@import '~advox-basic/src/style/abstract/button';
@import '~advox-basic/src/style/abstract/placeholder';

@import '../../../../advox-mergo-b2b/src/style/abstract/button';

.CheckoutOrderSummary {
    &-OrderTotals {
        @include desktop {
            border-radius: 0;
        }
    }
    &-ButtonWrapper .CheckoutOrderSummary-SummaryItem_isTotal {
        p,
        strong {
            color: $black;
            letter-spacing: initial;
        }
    }
    &-Text {
        color: $black;
        letter-spacing: initial;
    }
    &-SummaryItem {
        > strong {
            .ProductPrice-SubPrice,
            > span {
                display: flex;
                justify-content: flex-end;
                align-items: baseline;
                width: 100%;
                text-align: right;
            }
            .ProductPrice-SubPrice,
            .ProductPrice-PriceValue {
                color: $grey3;
            }
            .ProductPrice-Price,
            .ProductPrice-SubPrice {
                span {
                    margin-top: 0;
                }
            }

            .ProductPrice-SubPrice {
                span:last-child {
                    font-weight: bold;
                    color: $black;
                }
            }
        }
        &_isTotal {
            .ProductPrice-Price,
            .ProductPrice-SubPrice {
                span {
                    margin-top: 0;
                }
                span:last-child {
                    font-size: 18px;
                }
            }
        }
    }
    .Checkout-Heading {
        font-weight: bold;
    }
}
