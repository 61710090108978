@import '../../../../advox-mergo-b2b/src/style/abstract/variables';

@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

@import '~advox-basic/src/style/abstract/media';
@import '~advox-basic/src/style/abstract/mixins';

@import '~advox-basic/src/style/abstract/button';
@import '~advox-basic/src/style/abstract/placeholder';

@import '../../../../advox-mergo-b2b/src/style/abstract/button';

:root {
    --checkout-delivery-border-radius: 10px;
    --checkout-delivery-border-color: #{$default-secondary-base-color};
}

[dir='ltr'] .CheckoutDeliveryOption {
    padding: 0;
    border: 0;
    margin-left: -1px;
    margin-right: -1px;
    margin-top: -1px;

    &:first-child .CheckoutDeliveryOption-Button {
        border-radius: var(--checkout-delivery-border-radius) var(--checkout-delivery-border-radius) 0 0;
    }

    &:last-child .CheckoutDeliveryOption-Button {
        border-radius: 0 0 var(--checkout-delivery-border-radius) var(--checkout-delivery-border-radius);
    }

    &::before,
    &::after {
        content: '';
        display: block;
        background: $white;
        height: 1px;
        position: absolute;
        bottom: 0;
        left: 1px;
        right: 1px;
        width: auto;
        z-index: -1;
    }

    &::before {
        top: 0;
        left: 1px;
        right: 1px;
    }

    &:hover {
        &::before,
        &::after {
            z-index: 1;
        }

        .CheckoutDeliveryOption-Button {
            border-radius: var(--checkout-delivery-border-radius);
            border-color: var(--checkout-delivery-border-color);
            z-index: 2;
        }

        .CheckoutDeliveryOption-IconContainer > svg {
            opacity: 1;
        }
    }

    &_isSelected {
        &::before,
        &::after {
            z-index: 1;
        }

        &:first-child .CheckoutDeliveryOption-Button,
        .CheckoutDeliveryOption-Button {
            border-radius: var(--checkout-delivery-border-radius);
            border-color: var(--checkout-delivery-border-color);
        }

        &:last-child .CheckoutDeliveryOption-Button {
            border-radius: var(--checkout-delivery-border-radius);
        }

        .CheckoutDeliveryOption-Button {
            z-index: 2;
        }

        .CheckoutDeliveryOption-IconContainer > svg {
            opacity: 1;
        }
    }

    &-Button {
        width: 100%;
        padding: 19px 13px;
        border: 1px solid $grey5;
        background: $white;
        display: block;

        &_isDisabled {
            background: $grey-light;
        }

        @include from-tablet {
            padding: 20px 22px 20px 33px;
        }
    }
    &-Row {
        @include from-tablet {
            width: 100%;
            display: flex;
            justify-content: space-between;
        }
    }
    &-AdditionalInfo {
        margin-left: 29px;
        @include from-tablet {
            display: flex;
            flex: 1;
            align-items: center;
            justify-content: space-between;
            margin-left: 16px;
            padding-left: 16px;
        }
    }
    &-Price {
        font-family: $font-secondary;
        font-weight: bold;
        font-size: 13px;
        line-height: 150%;
        color: $black;
        margin-top: 10px;
        @include from-tablet {
            margin-top: 0;
            margin-left: auto;
            font-size: 15px;
        }
    }
    &-IconContainer {
        display: inline-block;
        max-width: 62px;
        min-width: 62px;
        margin-right: 15px;
        text-align: center;
        padding-right: 15px;

        @include from-tablet {
            max-width: 72px;
            min-width: 72px;
            margin-right: 23px;
        }

        svg {
            opacity: 0.5;
        }
    }
    &-CarrierInfo {
        display: flex;
        align-items: center;
        > svg {
            min-width: 16px;
            width: 16px;
            height: 16px;
            margin-right: 12px;
            rect:first-of-type {
                stroke: $grey-dark;
            }
            @include from-tablet {
                margin-right: 30px;
            }
        }

        &_isSelected {
            > svg {
                rect:first-of-type {
                    stroke: $orange50;
                }
            }
        }
    }
    &-CarrierTitle,
    &-MethodTitle {
        font-family: $font-secondary;
        font-weight: normal;
        font-size: 13px;
        line-height: 150%;
        color: $black;
        @include from-tablet {
            font-size: 15px;
        }
    }

    &-MethodTitle {
        color: $grey3;
        margin-top: 10px;
        @include from-tablet {
            order: -1;
            margin-top: 0;
            font-size: 13px;
        }
    }
}
