@import '../../../../advox-mergo-b2b/src/style/abstract/variables';

@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

@import '~advox-basic/src/style/abstract/media';
@import '~advox-basic/src/style/abstract/mixins';

@import '~advox-basic/src/style/abstract/button';
@import '~advox-basic/src/style/abstract/placeholder';

@import '../../../../advox-mergo-b2b/src/style/abstract/button';

.CheckoutOrderSummary {
    border: 0;

    .Checkout-Heading {
        display: flex;
        align-items: center;

        span {
            color: $grey-dark;
            margin: 0 0 0 16px;
            font-family: $font-secondary;
            font-weight: normal;
            font-size: 15px;
            line-height: 150%;
        }
    }
    &-ButtonWrapper {
        --footer-totals-height: 192px;
        background-color: $grey-light;
        padding-top: 25px;
        padding-bottom: 25px;
        bottom: 0;
        border: 0;

        @include mobile {
            padding-left: var(--content-padding);
            padding-right: var(--content-padding);
        }

        @include desktop {
            padding: 0;
            border: 0;
        }

        .CheckoutOrderSummary-SummaryItem_isTotal {
            align-items: baseline;
            @include desktop {
                border: 0;
            }

            p,
            strong {
                font-family: $font-primary;
                font-weight: bold;
                font-size: 20px;
                line-height: 135%;
                text-align: right;
                letter-spacing: 0.07em;
                color: $default-primary-base-color;
            }

            strong {
                color: $black;

                span {
                    color: $grey-dark;
                    font-family: $font-secondary;
                    font-weight: normal;
                    font-size: 15px;
                    line-height: 120%;
                    letter-spacing: -0.2px;
                    color: $grey-dark;
                    margin-top: 7px;
                }
            }
        }
    }
    &-Text {
        font-family: $font-secondary;
        font-weight: normal;
        font-size: 15px;
        line-height: 120%;
        letter-spacing: -0.2px;
        color: $grey-dark;
        padding: 5px 0;
    }

    &-OrderTotals {
        background-color: $grey-light;

        @include tablet {
            padding: 25px 0;
        }

        @include desktop {
            padding: 32px;
            border-radius: 10px;
        }
    }

    &-SummaryItem {
        padding: 0;
        margin: 5px 0;
        display: flex;
        align-items: center;
        &:first-of-type:not(.CheckoutOrderSummary-SummaryItem_isTotal) {
            margin: 0 0 5px 0;
        }

        &_divider {
            @include tablet {
                margin: 0 0 20px;
            }

            @include desktop {
                margin: 0 0 10px;
            }
        }
        &:not(.CheckoutOrderSummary-SummaryItem_isTotal) {
            @include mobileAndTablet {
                display: none;
            }
        }

        &_isTotal {
            padding: 0;
        }
    }
    &-ExpandableContentButton {
        padding: 25px 0 35px;
        cursor: pointer;
        .ChevronIcon {
            fill: $blue50;
        }
    }
    &-ExpandableContentHeading {
        @include mobileAndTablet {
            font-family: $font-primary;
            font-weight: bold;
            font-size: 22px;
            line-height: 135%;
            letter-spacing: 0.07em;
            color: $grey-dark;
        }
    }

    .CartItem-Wrapper {
        @include desktop {
            grid-template-columns: 147px 1fr;
        }
    }
}
