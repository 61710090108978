@import '../../../../advox-mergo-b2b/src/style/abstract/variables';

@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

@import '~advox-basic/src/style/abstract/media';
@import '~advox-basic/src/style/abstract/mixins';

@import '~advox-basic/src/style/abstract/button';
@import '~advox-basic/src/style/abstract/placeholder';

@import '../../../../advox-mergo-b2b/src/style/abstract/button';

:root {
    --checkout-payment-border-radius: 0;
}

[dir='ltr'] .CheckoutPayment {
    &-MethodIcon {
        margin-left: auto;
        margin-right: 10px;
    }
    &-MerchantLimitLeft {
        width: auto;
        margin-right: 10px;
        margin-left: auto;
    }
    &-Button {
        &_isSelected {
            > svg {
                rect {
                    &:first-of-type {
                        stroke: $default-primary-base-color;
                    }

                    &:last-of-type {
                        fill: $default-primary-base-color;
                    }
                }
            }
        }
    }
}
