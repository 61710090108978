@import '../../../../advox-mergo-b2b/src/style/abstract/variables';

@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

@import '~advox-basic/src/style/abstract/media';
@import '~advox-basic/src/style/abstract/mixins';

@import '~advox-basic/src/style/abstract/button';
@import '~advox-basic/src/style/abstract/placeholder';

@import '../../../../advox-mergo-b2b/src/style/abstract/button';

:root {
    --checkout-step-font: #{$font-primary};
    --checkout-step-title-color: #{$grey4};
    --checkout-step-count-background: #{$grey4};
    --checkout-step-count-color: #{$white};
    --checkout-step-separator-background: #{$grey4};
    --checkout-step-active-title-color: #{$default-primary-base-color};
    --checkout-step-active-count-background: #{$default-secondary-base-color};
    --checkout-step-border-bottom: #{$grey5};
}

.CheckoutSteps {
    counter-reset: checkoutSteps;
    display: flex;
    padding: 16px 0;

    @include from-tablet {
        padding: 14px 0;
    }

    @include mobileAndTablet {
        position: relative;

        &:after {
            content: '';
            height: 1px;
            width: 100vw;
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
            background: var(--checkout-step-border-bottom);
        }
    }

    &-Step {
        &,
        > a,
        button {
            display: flex;
            align-items: center;
            cursor: pointer;
        }

        > a span,
        > button span {
            cursor: pointer;
        }

        &:after {
            content: '';
            height: 2px;
            background: var(--checkout-step-separator-background);
            display: block;

            @include desktop {
                width: 100px;
                max-width: 6vw;
                margin-right: 24px;
            }

            @include mobileAndTablet {
                width: 64px;
            }

            @include tablet {
                margin-right: 16px;
            }

            @include mobile {
                margin-right: 8px;
            }
        }

        &:last-of-type {
            &:after {
                display: none;
            }
        }

        &_active {
            .CheckoutSteps-Step {
                &Count {
                    background: var(--checkout-step-active-count-background);
                }

                &Title {
                    color: var(--checkout-step-active-title-color);
                }
            }
        }

        &Count {
            counter-increment: checkoutSteps;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            background: var(--checkout-step-count-background);

            @include desktop {
                margin-right: 24px;
            }

            @include from-tablet {
                width: 32px;
                height: 32px;
                min-width: 32px;
            }

            @include tablet {
                margin-right: 16px;
            }

            @include mobile {
                width: 24px;
                height: 24px;
                min-width: 24px;
                margin-right: 8px;
            }

            &:after {
                content: counter(checkoutSteps);
                color: var(--checkout-step-count-color);
                font-family: var(--checkout-step-font);
                font-weight: 700;

                @include from-tablet {
                    font-size: 15px;
                }

                @include mobile {
                    font-size: 12px;
                }
            }
        }

        &Title {
            font-size: 15px;
            font-family: var(--checkout-step-font);
            font-weight: 700;
            color: var(--checkout-step-title-color);
            margin-right: 24px;
            cursor: default;
        }
    }
}
